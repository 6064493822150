import { camel } from "@/assets/js/varStyle";
import { mapMutations, mapState } from "vuex";
import { isSafari } from '@/assets/js'
const mixin = {
    data: function () {
        return {
            roomId: -1,
            doctorAvatar: "",
            caseId: -1,
            cameraClosed: false,
            isComeInHourse: false,
        };
    },
    computed: {
        ...mapState({
            tim: (state) => state.chat.tim,
            isReady: (state) => state.chat.isReady,
            rtc: (state) => state.chat.rtc,
            isVideoClosed: (state) => state.chat.isVideoClosed,
            isChatroomClosed: (state) => state.chat.isChatroomClosed,
        }),
    },
    methods: {
        ...mapMutations({
            login: "doctor/login",
            setSDKReady: "chat/setSDKReady",
            videoClose: "chat/videoClose",
            chatroomClose: "chat/chatroomClose",
        }),
        getChatroomDetail(roomNumber) {
            let data = {
                room_number: roomNumber,
            };
            return new Promise((resolve, reject) => {
                this.chatroomsDetail(data)
                    .then((res) => {
                        resolve(camel(res));
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        setChatroomData(roomData) {
            const FOURTY_EIGHT_HOURS = 172800;
            this.roomId = roomData.id;
            this.doctorAvatar = roomData.doctor_avatar;
            this.caseId = roomData.patientCaseId;
            // 当status为2时表示问诊室已关闭
            // 参考文档：
            // https://www.teambition.com/project/60989e15945ed0947b35a031/app/5eba5fba6a92214d420a3219/workspaces/60b604927ccb9e0046c71f51/docs/60e8150641cef600017a8c8c
            if (roomData.status === 2) {
                if (this.type === "video") {
                    // 视像问诊问诊室关闭只影响视频
                    this.videoClose();
                    // 如果问诊室已关闭则检查是否在前后48小时时间限制内
                    if (
                        roomData.openingStartTime48 > FOURTY_EIGHT_HOURS ||
                        -1 * roomData.openingEndTime48 > FOURTY_EIGHT_HOURS
                    ) {
                        this.chatroomClose();
                    }
                } else if (this.type === "text") {
                    // 文字问诊问诊室关闭则完全关闭
                    this.chatroomClose();
                }
            }

        },
        prepareSDK() {
            // TIM相关操作
            // if (!this.isReady) {
            // 当sdk准备就绪后通知其他组件
            this.tim.onReady = () => {
                this.setSDKReady();
            };
            // 登录tim
            this.tim.login({ userID: this.userID, userSig: this.userSig });
            // }
        },
        //聲音開關
        switchMute() {
            this.setMuteUrl = !this.setMuteUrl;
            if (!this.setMuteUrl) {
                this.rtc.localStream.unmuteAudio();//開啓聲音
            } else {
                this.rtc.localStream.muteAudio()//關閉聲音
            }
        },
        switchCamera() {
            this.setCamera = !this.setCamera;
            if (this.cameraClosed) {
                this.rtc.localStream && this.rtc.localStream.unmuteVideo();
                this.cameraClosed = false;
            } else {
                this.rtc.localStream && this.rtc.localStream.muteVideo();
                this.cameraClosed = true;
            }
        },
        async TRTCInit() {
            // 檢測是否支持RTC
            const support = await this.rtc.detectRTC();
            if (support === false) {
                this.$message({
                    type: "error",
                    message: "當前瀏覽器不支持視頻通訊",
                });
                return false;
            }
            await this.$nextTick();

            // 创建实例
            this.rtc.createClient({
                userId: this.userID,
                userSig: this.userSig,
            });

            // 添加各种事件的监听器
            this.registerTRTCEventListener();

            // 进入房间
            let roomstatus = await this.rtc.joinRoom({
                roomId: this.roomId,
            });

            if (!roomstatus.type) {
                this.$message({
                    type: "error",
                    message: "加入房間失敗",
                });
                console.log(roomstatus);
                return false;
            }

            // 告知后台用户进入了聊天室，不影响前台逻辑
            this.chatroomslogin({ chatroom_id: this.roomId });

            this.rtc
                .getLocalStream({ userId: this.userID })
                .then((localStream) => {
                    this.rtc.localStream = localStream;
                    // 这里会判断是否显示html内容，所以需要 $nextTick 过后推流
                    this.$nextTick(() => {
                        localStream.play("local-camera", {
                            objectFit: "cover",
                            muted: true
                        });
                        // 发布本地视频流
                        this.rtc.publishLocalStream(localStream);
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "未檢測到您的攝像頭",
                    });
                });
        },
        registerTRTCEventListener() {



            // 监听远端流订阅成功事件
            this.rtc.on("stream-subscribed", (event) => {
                console.log("监听远端流订阅成功事件");
                const remoteStream = event.stream;

                if (this.userID.indexOf("doctor")) {
                    remoteStream.play("remote-camera", {
                        objectFit: "cover",
                        muted: false
                    });
                } else {
                    remoteStream.play("remote-camera", {
                        objectFit: "contain",
                        muted: false
                    });
                }

                remoteStream.on("player-state-changed", (e) => {
                    if (event.state === "PAUSED") {
                        remoteStream.resume();
                    }
                });
                remoteStream.play('remote').catch(error => {
                    this.$alert(
                        "為確保視像和聲音暢順，請確保裝置對視像和聲音的權限保持開放。",
                        "溫馨提示",
                        {
                            confirmButtonText: "確認",
                            callback: (action) => {
                                remoteStream.resume();
                            },
                        }
                    );
                });
                remoteStream.on('error', error => {
                    if (error.getCode() === 0x4043) {
                        this.rtc.on('error', error => {
                            if (error.getCode() === 0x4043) {
                                // 自动播放受限导致播放失败，此时引导用户点击页面。
                                // 在点击事件的回调函数中，执行 stream.resume();
                                this.$alert(
                                    "為確保視像和聲音暢順，請確保裝置對視像和聲音的權限保持開放。",
                                    "溫馨提示",
                                    {
                                        confirmButtonText: "確認",
                                        callback: (action) => {
                                            remoteStream.resume();
                                        },
                                    }
                                );
                            }
                        })
                    }
                })
            });

            // 监听远端流增加事件
            this.rtc.on("stream-added", (event) => {
                console.log("监听远端流增加事件");
                const remoteStream = event.stream;
                this.rtc.remoteStream = remoteStream;
                this.rtc.subscribe(remoteStream, { audio: true, video: true });
            });

            // 远端用户进房通知，只有主动推流的远端用户进房才会收到该通知。
            this.rtc.on("peer-join", () => {
                // console.log(this.userID);
                if (this.userID.indexOf("doctor")) {
                    this.$message({
                        type: "success",
                        message: "醫生進入房間",
                    });
                } else {
                    this.$message({
                        type: "success",
                        message: "病人進入房間",
                    });
                }
                this.isComeInHourse = true;//底部显示状态
            });
            // 远端用户退房通知，只有主动推流的远端用户退房才会收到该通知。
            this.rtc.on("peer-leave", () => {
                if (this.userID.indexOf("doctor")) {
                    this.$message({
                        type: "error",
                        message: "醫生離開房間",
                    });
                } else {
                    this.$message({
                        type: "error",
                        message: "病人離開房間",
                    });
                }
                this.isComeInHourse = false;//设置离开状态
            });

            // 信令通道连接状态变化事件
            this.rtc.on("connection-state-changed", (event) => {
                const curState = event.curState;
                if (curState === "DISCONNECTED") {
                    this.$message({
                        type: "error",
                        message: "連接斷開",
                    });
                    console.log("連接斷開");
                } else if (curState === "CONNECTING") {
                    console.log("正在连接中");
                } else if (curState === "RECONNECTING") {
                    console.log("自动重连中");
                } else if (curState === "CONNECTED") {
                    console.log("已连接");
                }
            });

            // 远端流更新事件，当远端用户添加、移除或更换音视频轨道后会收到该通知。
            this.rtc.on("stream-updated", (event) => {
                console.log(
                    "远端流更新事件，当远端用户添加、移除或更换音视频轨道后会收到该通知。"
                );
                const remoteStream = event.stream;
                remoteStream.resume();
            });

            // 远端流移除事件，当远端用户取消发布流后会收到该通知。
            this.rtc.on("stream-removed", (event) => {
                const remoteStream = event.stream;
                remoteStream.stop();
                // this.$message("對方關閉攝像頭");
            });

            // 监听被T下线
            this.rtc.on("client-banned", () => {

                this.$alert("您的帳號在其他設備登錄", "提示", {
                    confirmButtonText: "確定",
                    callback: () => {
                        window.webBackToAndroid.onBack();
                        window.webkit.messageHandlers.getDataFromWeb.postMessage(
                            "close"
                        );
                        window.location.href = "iMeddy://com.imeddy.client?close=1";
                        //         this.rtc.leaveRoom().then(() => {
                        //             // this.$router.push({ name: "login" });
                        //             // this.$router.back(-1);
                        //         });
                        //         // this.goBackApp();
                    },
                });
            });
        },
    },
    //監聽有其他設備登錄賬號就直接退出網頁 返回原生
    goBackApp() {
        let isSa = isSafari();
        // 安卓端关闭网页方法
        if (window.webBackToAndroid) {
            window.webBackToAndroid.onBack();
        } else if (window.webkit) {
            window.webkit.messageHandlers.getDataFromWeb.postMessage(
                "close"
            );
        } else if (isSa) {
            // iOS端关闭网页方法
            window.location.href = "iMeddy://com.imeddy.client?close=1";
        }
        else {
            console.log('关闭网页方法')
            window.location.href = "about:blank";
            window.close();
        }
    },


    beforeDestroy() {
        if (this.rtc != null) {
            this.rtc.leaveRoom().catch(err => {
                // console.log(err)
            })
        }

    }
};
export default mixin;
