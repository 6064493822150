import TIM from "tim-js-sdk-ws";
import { getFileType, createFileURL, loadImage } from "@/assets/js";
import { downFile } from "@/assets/js/download";
import audioUrl from "../../../assets/music/mesg_auto1.ogg";
const mixin = {
    data: function () {
        return {
            sendText: "",
            messageList: [],
            nowList: [],
            myAudio: null,
        };
    },
    props: {
        roomId: {
            type: Number,
            required: true,
        },
        isMsg: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        fileSize: function () {
            return (size) => {
                let mega = (size / 1024 / 1024).toFixed(2);
                return `${mega}MB`;
            };
        },
    },
    methods: {
        resend(message, index) {
            if (!message.error || message.type != "text") return;
            let data = {
                to: this.roomId,
                type: 1,
                text: message.content,
            };
            this.messageList.splice(index, 1);
            message.loading = true;
            message.error = false;
            this.messageList.push(message);
            this.sendMessage(data, message);
        },
        sendMessage(data, message) {
            let index = this.messageList.length - 1;
            if (this.$store.state.chat.isChatroomClosed) {
                this.$message({
                    type: "error",
                    message: "問診室已關閉",
                });
                this.messageList.pop();
                return;
            }
            this.tim
                .sendMessage(data)
                .catch((err) => {
                    message.error = true;
                    this.$message({
                        type: "error",
                        message: err.message,
                    });
                    if (["file", "image"].includes(message.type)) {
                        this.messageList.splice(index, 1);
                    }
                })
                .finally(() => {
                    message.loading = false;
                });
        },
        handleKeyCode(event) {
            if (event.keyCode == 13) {
                if (event.shiftKey || event.ctrlKey) {
                    this.sendText = this.sendText + "\n";
                } else {
                    event.preventDefault();
                    this.handleTextSend();
                }
            }
        },
        handleTextSend() {
            if (!this.sendText) return false;
            if (!this.isReady) return false;

            let message = {
                type: "text",
                sender: this.userType,
                content: this.sendText,
                loading: true,
                error: false,
            };
            this.messageList.push(message);
            this.scrollDown();

            let data = {
                to: this.roomId,
                type: 1,
                text: this.sendText,
            };
            this.sendText = "";
            this.sendMessage(data, message);
        },
        msgTypeProcessing(type) {
            let timMsgType = [
                TIM.TYPES.MSG_TEXT,
                TIM.TYPES.MSG_IMAGE,
                TIM.TYPES.MSG_FILE,
            ];
            let localMsgType = ["text", "image", "file"];
            return localMsgType[timMsgType.indexOf(type)];
        },
        scrollDown() {
            this.$nextTick(() => {
                let element = this.$refs.chatMain;
                element.scrollTop = element.scrollHeight;
                this.getSystemOpen();
                // if(element!=null){
                //     element.scrollTop = element.scrollHeight;
                // }else{
                //     this.$message({
                //         type: "error",
                //         message: "房间有误，为您刷新",
                //     });
                //     location.reload();
                //     this.$emit("newMessage");
                // }
            });
        },
        clickUpload() {
            this.$refs.fileInput.dispatchEvent(new MouseEvent("click"));
        },
        changeFile(event) {
            let file = this.$refs.fileInput.files[0];
            const fileType = getFileType(file);
            if (fileType.type === "image") {
                this.handleImageSend(file, event.target);
            } else {
                this.handleFileSend(file, event.target);
            }
        },
        handleImageSend(file, picker) {
            if (!this.isReady) return false;
            const src = createFileURL(file);
            let message = {
                type: "image",
                sender: this.userType,
                image: src,
                miniImage: src,
                picker: picker,
                loading: true,
            };
            this.messageList.push(message);
            loadImage(src).then(() => {
                this.scrollDown();
            });
            let data = {
                to: this.roomId,
                type: 2,
                picker,
            };
            this.sendMessage(data, message);
            picker.value = null;
        },
        handleFileSend(file, picker) {
            if (!this.isReady) return false;
            let message = {
                type: "file",
                sender: this.userType,
                file: {
                    name: file.name,
                    size: file.size,
                    url: createFileURL(file),
                },
                picker,
                uploadProgress: "0%",
                loading: true,
            };
            this.messageList.push(message);
            this.scrollDown();
            let data = {
                to: this.roomId,
                type: 3,
                picker,
                onProgress: (e) => {
                    message.uploadProgress = `${e * 100}%`;
                },
            };
            this.sendMessage(data, message);
            picker.value = null;
        },
        downloadFile(item) {
            // let aLink = document.createElement('a')
            // aLink.download = item.file.name;
            // aLink.href = item.file.url;
            // aLink.click();
            // aLink.style = "none";

            // window.open('https://view.officeapps.live.com/op/view.aspx?src=' + item.file.url)
            // console.log(item.file.url);
            // if (item.file.url.indexOf('.pdf') >= 0) {
            //     this.$router.push({
            //         name: "orderDetails",
            //         query: {
            //             pdfUrl: item.file.url,
            //         },//進行打開頁面
            //     });
            // } else if (item.file.url.indexOf('.doc') >= 0 ||
            //     item.file.url.indexOf('.docx') >= 0 ||
            //     item.file.url.indexOf('.xlsx') >= 0 || item.file.url.indexOf('.xls') >= 0 ||
            //     item.file.url.indexOf('.ppt') >= 0 || item.file.url.indexOf('.pptx') >= 0
            // ) {
            //     window.open('https://view.officeapps.live.com/op/view.aspx?src=' + item.file.url)//原本就是使用微信的解析拼接
            // } else {
            //     if (item.file.name.indexOf('.pdf') >= 0) {
            //         window.open('https://docs.google.com/viewer?embedded=true&url=' + item.file.url)//是當前IM 下的鏈接沒用準確的鏈接 所以需要google 拼接
            //     } else if (item.file.name.indexOf('.doc') >= 0 ||
            //         item.file.name.indexOf('.docx') >= 0 ||
            //         item.file.name.indexOf('.xlsx') >= 0 || item.file.name.indexOf('.xls') >= 0 ||
            //         item.file.name.indexOf('.ppt') >= 0 || item.file.name.indexOf('.pptx') >= 0
            //     ) {
            //         window.open('https://view.officeapps.live.com/op/view.aspx?src=' + item.file.url)
            //     } else {
            //         window.open(item.file.url)//直接打開該鏈接
            //     }
            // }
            this.$message({
                showClose: true,
                type: "success",
                message: "正在打開中請耐心等待",
            });
            if (item.file.name.indexOf('.pdf') >= 0) {
                window.open('https://docs.google.com/viewer?embedded=true&url=' + item.file.url)//是當前IM 下的鏈接沒用準確的鏈接 所以需要google 拼接
            } else if (item.file.name.indexOf('.doc') >= 0 ||
                item.file.name.indexOf('.docx') >= 0 ||
                item.file.name.indexOf('.xlsx') >= 0 || item.file.name.indexOf('.xls') >= 0 ||
                item.file.name.indexOf('.ppt') >= 0 || item.file.name.indexOf('.pptx') >= 0
            ) {
                window.open('https://view.officeapps.live.com/op/view.aspx?src=' + item.file.url)
            } else {
                window.open(item.file.url)//直接打開該鏈接
            }

            // let title = item.file.name;
            // let url = item.file.url;
            // downFile(url, title).catch((error) => {
            //     if (error.code === 401) return false;
            //     this.$message({
            //         type: "error",
            //         message: error,
            //     });
            // });
        },
        setAutoPlayer() {
            // this.myAudio = null;
            if (this.myAudio != null) {
                // 开启自动播放
                this.myAudio.play(); // 播放
            } else {
                this.myAudio = new Audio();
                // 开启自动播放
                this.myAudio.src = audioUrl;
                this.myAudio.autoplay = false;
                this.myAudio.play(); // 播放
            }
            this.getSystemOpen();

        },
        //判断系统 是否为对应系统 https://blog.csdn.net/johnWcheung/article/details/101308171
        getSystemOpen() {
            let p = navigator.platform;
            let u = navigator.userAgent;
            let is_android = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
            let is_ios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            if (
                p == "Win32" ||
                p == "Win64" ||
                p == "MacPPC" ||
                p == "MacIntel" ||
                p == "X11" ||
                p == "Linux i686"
            ) {
                //PC调试环境
                // alert('PC环境--供PC端调试'); pc进行测试请求
                //this.callBackReadDialog(id);
            } else {
                if (is_android) {
                    //Android终端
                } else if (is_ios) {
                    //IOS终端
                    this.$refs.audio.play();
                }
            }
        },

    },

    watch: {
        roomId: function () {
            if (this.roomId != -1)
                this.chatroomMessages({
                    chatroom_id: this.roomId,
                    pageSize: 999,
                }).then((res) => {
                    res.data.dataList.forEach((e) => {
                        // 如果一条消息由多个不同类型的内容组成，MsgBody的长度就会大于1
                        // 但目前系统中只能发送一种类型组成的消息，所以只需要处理MsgBody[0]
                        let type = this.msgTypeProcessing(e.MsgBody[0].MsgType);
                        let sender = e.From_Account.split("_")[0];
                        switch (type) {
                            case "text":
                                this.messageList.push({
                                    type,
                                    sender,
                                    content: e.MsgBody[0].MsgContent.Text,
                                });
                                break;
                            case "image":
                                this.messageList.push({
                                    type,
                                    sender,
                                    image:
                                        e.MsgBody[0].MsgContent
                                            .ImageInfoArray[0].URL,
                                });
                                break;
                            case "file":
                                this.messageList.push({
                                    type,
                                    sender,
                                    file: {
                                        name: e.MsgBody[0].MsgContent.FileName,
                                        size: e.MsgBody[0].MsgContent.FileSize,
                                        url: e.MsgBody[0].MsgContent.Url,
                                    },
                                    uploadProgress: "100%",
                                });
                        }
                    });
                    this.scrollDown();
                    this.setAutoPlayer();
                });
        },
        isReady: function () {
            this.tim.onMessageReceived = (message) => {
                let type = this.msgTypeProcessing(message.type);
                console.log(message)
                let data = {
                    type,
                    sender: message.from.split("_")[0],
                };
                switch (type) {
                    case "text":
                        data.content = message.payload.text;
                        break;
                    case "image":
                        data.image = message.payload.imageInfoArray[0].imageUrl;
                        break;
                    case "file":
                        data.file = {
                            name: message.payload.fileName,
                            size: message.payload.fileSize,
                            url: message.payload.fileUrl,
                        };
                        data.uploadProgress = "100%";
                        break;
                }
                console.log(data);
                console.log("收到消息执行")
                this.$emit("newMessage");
                this.$emit("newMessageDoc");
                this.messageList.push(data);
                this.setAutoPlayer();
                this.scrollDown();
            };
        },
    },
};
export default mixin;
